import React from 'react'
import PropTypes from 'prop-types'
import { BaseHeading } from './Heading'
import { Flex, Box } from './Primitives'
import { Logo } from './Logo'
import { Divider } from './Divider'
import styled from 'styled-components'
import { layout, typography, color, space, grid } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

const Container = Box

const Heading = styled(BaseHeading).withConfig({ shouldForwardProp }).attrs({
  mb: 3,
  fontSize: 0,
  color: 'darkbluegray',
})({ fontFamily: 'inherit' }, typography, color, space)

const Section = ({ heading, children }) => (
  <Box>
    <Heading>{heading}</Heading>
    <div>{children}</div>
  </Box>
)

const FooterContainer = styled('div').withConfig({ shouldForwardProp })(
  {
    display: 'grid',
  },
  grid,
)

const FooterLinks = ({ accountsExternal, accountsURL, homeExternal, homeURL, LinkComponent }) => {
  return (
    <FooterContainer
      gridRowGap={4}
      gridColumnGap={3}
      gridTemplateColumns="repeat(auto-fit, minmax(125px, 1fr))"
      gridAutoRows="auto"
    >
      <Section heading="Apps">
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/actions`}
        >
          Actions
        </LinkComponent>
        <LinkComponent fontSize={0} color="bluegray" external={homeExternal} to={`${homeURL}/flow`}>
          Flow
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/timepage`}
        >
          Timepage
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/overlap`}
        >
          Overlap
        </LinkComponent>
      </Section>
      <Section heading="Support">
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/support`}
          exact
        >
          Support
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/support/actions`}
        >
          Actions User Guide
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/support/flow`}
        >
          Flow User Guide
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/support/overlap`}
        >
          Overlap User Guide
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/support/timepage`}
        >
          Timepage User Guide
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/support/membership`}
        >
          Membership FAQ
        </LinkComponent>
        {false && (
          <LinkComponent
            fontSize={0}
            color="bluegray"
            external={homeExternal}
            to={`${homeURL}/status`}
          >
            System Status
          </LinkComponent>
        )}
        {false && (
          <LinkComponent
            fontSize={0}
            color="bluegray"
            external={homeExternal}
            to={`${homeURL}/contact`}
          >
            Contact Us
          </LinkComponent>
        )}
      </Section>
      <Section heading="Account">
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={accountsExternal}
          to={`${accountsURL}/account`}
        >
          Manage Account
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={accountsExternal}
          to={`${accountsURL}/login`}
        >
          Create Account
        </LinkComponent>
      </Section>
      <Section heading="Community">
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={accountsExternal}
          to={`${accountsURL}/feedback`}
        >
          Vote for Features
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external
          to="https://www.instagram.com/moleskinestudio"
          newTab
        >
          Instagram
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external
          to="https://medium.com/moleskine-digital-studio"
          newTab
        >
          Medium
        </LinkComponent>
        {false && (
          <LinkComponent
            fontSize={0}
            color="bluegray"
            external={homeExternal}
            to={`${homeURL}/whats-new`}
          >
            What&apos;s New
          </LinkComponent>
        )}
        {false && (
          <LinkComponent
            fontSize={0}
            color="bluegray"
            external={homeExternal}
            to={`${homeURL}/newsletter`}
          >
            Newsletter
          </LinkComponent>
        )}
        {false && (
          <LinkComponent
            fontSize={0}
            color="bluegray"
            external={homeExternal}
            to={`${homeURL}/beta`}
          >
            Beta Program
          </LinkComponent>
        )}
      </Section>
      <Section heading="Bonobo">
        {false && (
          <LinkComponent
            fontSize={0}
            color="bluegray"
            external={homeExternal}
            to={`${homeURL}/about`}
          >
            About
          </LinkComponent>
        )}
        <LinkComponent fontSize={0} color="bluegray" external to={homeURL} newTab>
          Home
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/press`}
        >
          Press
        </LinkComponent>
        <LinkComponent
          fontSize={0}
          color="bluegray"
          external={homeExternal}
          to={`${homeURL}/contact`}
        >
          Contact Us
        </LinkComponent>
      </Section>
    </FooterContainer>
  )
}

const Text = styled('span').withConfig({ shouldForwardProp })(typography)
const Bullet = () => <Text color="bluegray">&nbsp;&bull;&nbsp;</Text>

const DisplayFlex = styled(Flex)
  .withConfig({
    shouldForwardProp,
  })
  .attrs({
    display: ['block', 'flex'],
  })(layout)

const FooterBottom = ({ homeExternal, homeURL, children, LinkComponent }) => (
  <DisplayFlex alignItems="center" mt={[4, 3]}>
    {children}
    <Box mx="auto" />
    <Flex alignItems="center" mt={[4, 0]}>
      <LinkComponent
        fontSize={0}
        external={homeExternal}
        to={`${homeURL}/privacy`}
        mr={2}
        lineHeight={1}
        color="bluegray"
      >
        Privacy & Terms
      </LinkComponent>
      {false && <Bullet />}
      {false && (
        <LinkComponent to="/sitemap" ml={2} lineHeight={1} fontSize={0} color="bluegray">
          Sitemap
        </LinkComponent>
      )}
    </Flex>
  </DisplayFlex>
)

const CopyrightText = styled('div').withConfig({ shouldForwardProp })(color, typography, space)

const Copyright = ({ copyright = 'Bonobo Pte Lte', LinkComponent }) => (
  <CopyrightText color="bluegray" fontSize={10} mt={[1, 3]}>
    {/* This has full browser compatability but does require javascript to work
          See https://github.com/gatsbyjs/gatsby/pull/10759/files for agreed-upon solution */}
    Copyright © {new Date().getFullYear()} {copyright}
    <Bullet />
    <Box css={{ display: 'inline-block' }}>
      Made with ♥ by&nbsp;
      <LinkComponent
        color="bluegray"
        external
        to="https://bonobolabs.com"
        fontSize={10}
        inline
        newTab
      >
        Bonobo 🦍
      </LinkComponent>
    </Box>
  </CopyrightText>
)

/**
 * Footer component.
 *
 * This component renders the footer section of the website. It includes links to the home and accounts pages,
 * a logo, and copyright information. The links can be customized to be internal or external based on the `currentSite` prop.
 *
 * @component
 * @example
 * // Usage example:
 * <Footer currentSite="home" LinkComponent={YourLinkComponent} />
 *
 * @param {Object} props - The props that are passed to the component.
 * @param {string} [props.bg='sidebarblue'] - The background color of the footer.
 * @param {number} [props.maxWidth=900] - The maximum width of the footer container.
 * @param {string} [props.currentSite] - The current site context, which determines the behavior of the links.
 * @param {string} [props.accountsURL='https://accounts.moleskinestudio.com'] - The URL for the accounts page.
 * @param {string} [props.homeURL='https://moleskinestudio.com'] - The URL for the home page.
 * @param {React.ReactNode} [props.children] - The children to render inside the footer.
 * @param {Array} [props.py=[4, 5]] - The vertical padding of the footer container.
 * @param {Array} [props.px=[4, 5]] - The horizontal padding of the footer container.
 * @param {React.ComponentType} props.LinkComponent - The custom link component to wrap the links.
 * @returns {JSX.Element} The Footer component.
 */
export const Footer = ({
  bg = 'sidebarblue',
  maxWidth = 900,
  currentSite,
  accountsURL = 'https://accounts.moleskinestudio.com',
  homeURL = 'https://moleskinestudio.com',
  children,
  py = [4, 5],
  px = [4, 5],
  LinkComponent,
  ...props
}) => {
  // TODO find a nicer way than this
  let accountsExternal = true
  let homeExternal = true

  if (currentSite === 'home') {
    homeExternal = false
    homeURL = ''
  }
  if (currentSite === 'accounts') {
    accountsExternal = false
    accountsURL = ''
  }

  const linksData = {
    accountsExternal,
    accountsURL,
    homeExternal,
    homeURL,
  }

  return (
    <Box as="footer" bg={bg}>
      <Container {...props} maxWidth={maxWidth} px={px} py={py}>
        <FooterLinks currentSite={currentSite} {...linksData} LinkComponent={LinkComponent} />
        <Divider borderBottom="2px solid" borderColor="lightgreyborder" />
        <FooterBottom {...linksData} LinkComponent={LinkComponent}>
          {children || <Logo LinkComponent={LinkComponent} />}
        </FooterBottom>
        <Copyright copyright="Bonobo Pte Lte" LinkComponent={LinkComponent} />
      </Container>
    </Box>
  )
}

Footer.propTypes = {
  bg: PropTypes.string,
  maxWidth: PropTypes.number,
  currentSite: PropTypes.oneOf([null, 'accounts', 'home', 'actions']),
  accountsURL: PropTypes.string,
  homeURL: PropTypes.string,
  children: PropTypes.node,
  py: PropTypes.array,
  px: PropTypes.array,
  LinkComponent: PropTypes.elementType.isRequired,
}
