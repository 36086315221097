import React from 'react'
import Title from './title'
import { SideBySideLayout } from '../MDS-web-ui/src'
import { ActiveLink } from '../MDS-web-ui/src/components/Link/ReactRouterLink'
import { useTranslation } from 'react-i18next'
import { H2 } from './presentation/Headings'

const Missing = () => {
  const { t } = useTranslation()

  return (
    <Title page="404">
      <SideBySideLayout LinkComponent={ActiveLink}>
        <H2 mt={4}>{t('main.nothingHere')}</H2>
      </SideBySideLayout>
    </Title>
  )
}
export default Missing
